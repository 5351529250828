import http from '@/libs/http'
import { useStore } from '@/store'

export default (params) => {
  const store = useStore()
  const token = store.getters['auth/getToken']
  params.token = 'Bearer ' + token
  const url = `${process.env.VUE_APP_MICROSERVICE_API_UT_SEGUPHARMA_FACTURACION_MASIVA}/exportar_ov_excel`
  const res = http.getUri({ url, params })
  window.open(res, '_blank')
}
