<template>
  <section class="facturacion-evento">
    <div class="p-2 bg-white rounded-md mt-4 h-full">
      <div class="gap-12 my-3 items-center mx-6 flex justify-end">
        <router-link to="/ut-segupharma/historial" class="historial text-blue-800 flex items-center font-bold">
          <img class="mr-2" src="../../../../../public/img/icon_historial.svg" alt="Icono historial">
          Historial
        </router-link>
      </div>
      <div class="rounded-md bg-white p-5">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-12 my-3">
          <div class="flex justify-center w-full">
            <div class="w-full">
              <label for="">Cliente</label>
              <div class="flex items-center w-full my-1">
                <Dropdown
                    v-model="CardName"
                    :options="clientesInfo"
                    :filter="true"
                    optionLabel="CardName"
                    optionValue="CardCode"
                    placeholder="Seleccione"
                    panelClass="text-xs w-20"
                    class=" mt-1 w-full rounded-md border-gray-300"
                  >
                </Dropdown>
              </div>
            </div>
          </div>
          <div class="flex justify-center w-full">
            <div class="w-full">
              <label for="">Orden(es)</label>
              <input type="text" v-model="ordenSelected" @keyup.enter="insertarOrden" placeholder="Orden(es)"
                class="my-2 block placeholder-gray-700 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
              <div class="cajaIngresados bg-white rounded-md flex flex-wrap">
                <div class="mt-2" v-for="(seleccionada, o) in ordenesSeleccionadas" :key="o">
                  <div
                    class="p-1 mx-1 text-xs flex items-center w-fit text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
                    role="alert">
                    <button @click="borrarOrden(o)">
                      <svg class="w-3 h-3" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                        </path>
                      </svg>
                    </button>
                    {{ seleccionada }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <label for="">Fecha inicio</label>
            <input type="date"  v-model="filters.FechaDocumento[0]" class="p-2 mt-2 border w-full rounded-md">
          </div>
          <div class="">
            <label for="">Fecha fin</label>
            <input type="date" v-model="filters.FechaDocumento[1]" class="p-2 mt-2 border w-full rounded-md">
          </div>
          <div class="grid grid-cols-2">
            <Button class="my-auto mt-7 mx-2" @click="listarOrdenesVenta" label="Buscar" />
            <Button class="my-auto mt-7 mx-2" @click="limpiarFiltros" label="Limpiar" severity="secondary" />
          </div>
        </div>
        <div>
          <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <DataTable
              :value="ordenesInfo"
              tableStyle="min-width: 50rem"
              class="p-datatable-sm mt-4"
              v-model:selection="ordenesCheckeds"
              dataKey="Id"
              scrollHeight="600px" scrollable
            >
              <template #empty>No se encontraron registros.</template>
              <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
              <Column field="Id" class="text-center text-sm" header="#OV">
                <template #body="{data}">
                  <div class="text-center pt-3 idPadre">
                    <p class="text-blue-600">{{ data.Id }}</p>
                    <div class="flex gap-x-4 idHijo text-white transition ease-in-out duration-500">
                      <button @click="$router.push({ name: 'pharmasan.ventas.ov.ver', params: {id: data.Id} })" class=" underline italic">Ver</button>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field="CardName" class="text-center text-sm" header="Cliente"></Column>
              <Column field="FechaDocumento" class="text-center text-sm" header="Fecha de la orden"></Column>
              <Column class="text-center text-sm" header="Accion">
                <template #body="{ data }">
                  <div class="flex gap-2 items-center text-gray-600">
                    <Button class="my-auto mx-2" @click="displayFacturacionMasiva(data.Id)" label="Enviar" />
                  </div>
                </template>
              </Column>
            </DataTable>
            <div class="flex gap-2 items-center justify-center w-full">
              <p class="text-gray-600">
                Mostrando de {{totalRecords > 1 ? (offset +1) : offset}} a {{totalRecords > limit ? (ordenesInfo.length === limit ? limit + offset : ordenesInfo.length + offset) : totalRecords }} de {{totalRecords}}
              </p>
              <Paginator v-model:first="offset"
                :rows="limit"
                :totalRecords="totalRecords"
                :rowsPerPageOptions="[2,10,20,30,100, totalRecords]"
                @page="onPage($event)"
              />
            </div>
            <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-12 px-10 my-3 w-full items-center">
              <div>
                <p class="font-bold text-base">
                  Total selección: {{ ordenesCheckeds.length }}
                </p>
              </div>
              <div class="flex justify-between items-center">
                <p class="font-bold text-base">
                  Total Factura: {{ $h.formatCurrency(parseInt(entregasTotal)) }}
              </p>
              <button type="button" @click="displayFacturacionMasiva()"
                class="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                Facturación Masiva
              </button>
              <div v-if="modalFacturarMasivo" id="defaultModal" style="z-index: 52;"
                class="rounded-b border-t border-gray-200 overflow-y-auto bg-gray-100 bg-opacity-50 flex items-center justify-center overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
                <div class="relative p-4 w-full rounded-lg shadow border max-w-2xl h-full md:h-auto bg-white">
                  <div class="flex justify-center">
                    <svg class="w-20 h-20 text-yellow-600" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z">
                      </path>
                    </svg>
                  </div>
                  <div class="flex justify-center">
                    <div class="font-bold text-center text-3xl my-5">
                      <h1 class="mb-4">Facturación</h1>
                      <h1 class="mb-4">Total seleccionados: {{ ordenesCheckeds.length }}</h1>
                    </div>
                  </div>
                  <!-- Modal content -->
                  <div class="relative bg-white dark:bg-gray-700">
                    <!-- Modal footer -->
                    <div class="flex items-center p-6 space-x-2 justify-center  dark:border-gray-600">
                      <button @click="facturar" data-modal-toggle="defaultModal" type="button"
                        class="text-white bg-green-700 w-40 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Si,
                        generar!</button>
                      <button @click="cerrarModal1" data-modal-toggle="defaultModal" type="button"
                        class="text-white bg-red-700 w-40 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">No,
                        Cancelar!</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>
<script>
    import { onMounted, ref, watch, computed } from 'vue'
    import ClientesServices from '../services/clientes.service'
    import OrdenesVenta from '../services/ov.service'
    import exportarOvExcelService from '../services/exportarOvExcel.service'
    import OrdenesVentaFindChange from '../services/findOV.service'
    import FacturasEventoService from '../services/facturacionEvento.service'
    import dayjs from 'dayjs'
    import Swal from 'sweetalert2'

    export default {
        name: 'FacturacionMasivaEvento',
        setup () {
            // const _BodegaServices = ref(new BodegasServices())
            const _ClientesServices = ref(new ClientesServices())
            // const _MotivosAutorizacionService = ref(new MotivosAutorizacionService())
            const _OrdenesVenta = ref(new OrdenesVenta())
            const _Facturar = ref(new FacturasEventoService())
            const bodegasInfo = ref([])
            const clientesInfo = ref([])
            const ordenesInfo = ref([])
            const CardName = ref('')
            const bodegaSelected = ref({})
            const bodegasSelected = ref([])
            const entregas = ref([])
            const entregasValor = ref([])
            const entregasTotal = ref(0)
            const checkAll = ref(false)
            const modalFacturarMasivo = ref(false)
            const ordenSelected = ref('')
            const ordenesSeleccionadas = ref([])
            const motivoSeleccionado = ref('')
            const offset = ref(0)
            const totalRecords = ref(0)
            const rangoFechas = ref([dayjs().$d, dayjs().$d])
            const cantidadOvSegunUsuario = ref(0)
            const motivos = ref([])
            const checked = ref([])
            const mes = ref('')
            const comentario = ref('')
            const departamentos = ref([])
            const ciudad = ref('')
            const ciudadesPorDepartamento = ref([])
            const regimenSeleccionado = ref('')
            const valorUnitario = ref('')
            const departamento = ref('')
            const ordenesCheckeds = ref([])
            const facturaCero = ref('')
            const facturaBool = ref(false)
            const facturaCapita = ref(false)
            const mantenerOrden = ref(false)
            const limit = ref(10)
            const filters = ref({
                CardName: CardName.value,
                ovs: ordenesSeleccionadas.value,
                FechaDocumento: [
                  dayjs(rangoFechas.value[0]).format('YYYY-MM-DD'),
                  dayjs(rangoFechas.value[1]).format('YYYY-MM-DD')
                ]
            })
            const params = computed(() => {
              const data = {
                offset: offset.value,
                limit: limit.value,
                filters: {
                  ...filters.value
                }
              }
              for (const [key, value] of Object.entries(data.filters)) {
                if (!value) delete data.filters[key]
              }
              return data
            })
            const de = ref(0)
            const facturaCeroChangue = () => {
                facturaBool.value = !facturaBool.value
                if (facturaBool.value) {
                    facturaCero.value = 'Si'
                } else {
                    facturaCero.value = 'No'
                }
             }
            const listarClientes = () => {
                _ClientesServices.value.getClientes().then(({ data }) => {
                    clientesInfo.value = data
                })
            }
            const onPage = ({ rows }) => {
                limit.value = rows
                listarOrdenesVenta()
            }
            const exportExcel = () => {
              const params = {
                  filters: { ...filters.value }
              }
              return exportarOvExcelService(params)
            }
            const numerosRows = [10, 50, 100, 250, 500]
            const numRowSelected = ref(10)
            const listarOrdenesVenta = () => {
                _OrdenesVenta.value.getOrdenes(params.value).then(({ data }) => {
                    ordenesInfo.value = []
                    if (data.rows.length) {
                        ordenesInfo.value = data.rows.map((e, _key) => {
                          const ordenCheckedFind = ordenesCheckeds.value.find(f => f.DocNum === e.DocNumOrden)
                            return { ...e, checked: !!ordenCheckedFind, _key }
                        })
                    }
                    totalRecords.value = data.count
                })
            }
            const selectedNumRows = () => {
              offset.value = 0
              listarOrdenesVenta()
            }
            const borrarOrden = (indice) => {
                ordenesSeleccionadas.value.splice(indice, 1)
            }
            const limpiarFiltros = () => {
                CardName.value = ''
                ordenesSeleccionadas.value = []
                rangoFechas.value = [dayjs().$d, dayjs().$d]
                listarOrdenesVenta()
            }
            const displayFacturacionMasiva = (Id = null) => {
                if (!ordenesCheckeds.value.length && Id === null) {
                    Swal.fire({
                        text: 'Debes seleccionar al menos una Orden de venta',
                        icon: 'warning'
                    })
                } else {
                  if (Id && Id !== '' && Id !== null) {
                    ordenesCheckeds.value = []
                    ordenesCheckeds.value.push({ Id })
                  }
                    modalFacturarMasivo.value = !modalFacturarMasivo.value
                }
            }
            const cambiarOrdenOV = () => {
                mantenerOrden.value = !mantenerOrden.value
            }
            const cerrarModal1 = () => {
                modalFacturarMasivo.value = false
            }
            const insertarOrden = () => {
                const a = ordenSelected.value.split(' ')
                if (ordenSelected.value.split(' ').length === 1) {
                    ordenesSeleccionadas.value.push(ordenSelected.value)
                } else {
                    for (const i of a) {
                        ordenesSeleccionadas.value.push(i)
                    }
                }
                ordenSelected.value = ''
            }
            const facturar = (values) => {
                _Facturar.value.generarFacturaEvento({ ovs: ordenesCheckeds.value.map(m => m.Id) }).then(({ data }) => {
                  if (data) {
                    Swal.fire({
                        title: 'Guardado',
                        text: 'Las ordenes se enviaron a facturar con éxito',
                        icon: 'success'
                    }).then(() => {
                      cerrarModal1()
                      listarOrdenesVenta()
                    })
                  }
                })
            }

            watch(checkAll, (nuevo, old) => {
                if (nuevo) {
                    entregasValor.value = ordenesInfo.value.map(a => a.DocTotal)
                    const params = {
                        filters: {
                          ...filters.value,
                          DocNumOrden: []
                        }
                    }
                    OrdenesVentaFindChange(params).then(({ data }) => {
                      let DocTotal = 0
                      if (data.rows.length) {
                          ordenesCheckeds.value = data.rows.map((a, _key) => {
                          DocTotal += parseFloat(a.DocTotal)
                          return {
                                DocNum: a.DocNumOrden,
                                baseEntrys: a.BaseEntry,
                                _key
                            }
                          })
                      }
                      ordenesInfo.value = ordenesInfo.value.map(e => {
                          return { ...e, checked: true }
                      })
                      entregasTotal.value = DocTotal
                    })
                } else {
                    entregasValor.value = []
                    entregasTotal.value = 0
                    ordenesCheckeds.value = []
                    ordenesInfo.value = ordenesInfo.value.map(e => {
                        return { ...e, checked: false }
                    })
                }
            })
            onMounted(() => {
                listarClientes()
                listarOrdenesVenta()
            })
            return {
              bodegaSelected,
              bodegasInfo,
              checkAll,
              bodegasSelected,
              filters,
              CardName,
              clientesInfo,
              ordenesInfo,
              displayFacturacionMasiva,
              modalFacturarMasivo,
              limpiarFiltros,
              entregas,
              ordenSelected,
              insertarOrden,
              ordenesSeleccionadas,
              borrarOrden,
              motivoSeleccionado,
              motivos,
              cerrarModal1,
              offset,
              totalRecords,
              numerosRows,
              numRowSelected,
              listarOrdenesVenta,
              entregasTotal,
              entregasValor,
              dayjs,
              cantidadOvSegunUsuario,
              exportExcel,
              facturar,
              checked,
              mes,
              departamento,
              comentario,
              departamentos,
              ciudad,
              ciudadesPorDepartamento,
              ordenesCheckeds,
              onPage,
              facturaCeroChangue,
              facturaCapita,
              regimenSeleccionado,
              valorUnitario,
              mantenerOrden,
              cambiarOrdenOV,
              de,
              selectedNumRows,
              rangoFechas,
              limit
            }
        }
    }
</script>
<style lang="scss" scoped>
    input:checked ~ .dot {
    transform: translateX(100%);
    }
    input:checked ~ .dot-body {
        background-color: #1E40AF;
    }
    table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    }

    tr:nth-child(even) {
    background-color: #F6F8FB;
    }
    ::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
        background: #F1F5F9 0% 0% no-repeat padding-box;
        border-color: #E3F2FD;
        color: #495057;
    }
    ::v-deep(.p-paginator .p-paginator-pages .p-paginator-page){
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last){
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-prev, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-next, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    ::v-deep(.p-paginator .p-paginator-last, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last) {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0 none;
        color: black;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0.143rem;
        transition: box-shadow 0.2s;
        border-radius: 3px;
        border: 1px solid #F1F5F9;
        box-shadow: 0px 1px 3px #00000029;
    }
    .cajaIngresados {
        max-height: 200px;
        overflow-y: auto;
        padding: 5px;
    }
    .cajaIngresados {
        padding: 0rem 0.5rem;
    }
    .p-datatable-tbody tr:hover {
    .idHijo{
      color: blue
    }
  }
</style>
